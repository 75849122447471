<template>
  <div>
    <IbClientTabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols="12">
                <b-table
                  no-border-collapse
                  responsive
                  striped
                  hover
                  :fields="accountsfields"
                  :items="accounts"
                >
                  <template #cell(balance)="data">
                    <span>{{ numberWithCommas(data.item.balance.toFixed(2)) }}</span>
                  </template>
                  <template #cell(equity)="data">
                    <span>{{ numberWithCommas(data.item.equity.toFixed(2)) }}</span>
                  </template>

                  <template #cell(actions)="data">
                    <b-button
                      class="ml-1 mt-1"
                      variant="primary"
                      @click="moneyTransferHandle(data.item)"
                    >
                      {{ $t('money_transfer') }}
                    </b-button>
                    <b-button
                      class="ml-1 mt-1"
                      variant="primary"
                      :to="{name:'ib-metatrader-accounts-history',params:{userType:'IB',userID:$route.params.userID,accid:data.item.account}}"
                    >
                      {{ $t('account-history') }}
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- modal -->
    <b-modal
      v-model="transferModal"
      :title="$t('money_transfer')"
      size="md"
      ok-only
    >
      <template #modal-header="{ close }">
        <feather-icon
          icon="XIcon"
          size="16"
          style="cursor:pointer;"
          color="white"
          @click="close()"
        />
      </template>
      <b-row
        class="m-2"
        align-h="center"
        align-v="center"
      >
        <b-col cols="auto">
          {{ $t('type') }}
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mx-auto mb-0 type-style">
            <b-form-checkbox-group
              v-model="transferForm.type"
              button-size="sm"
              buttons
              class="ibpannel-checkbox-group"
              name="butons1"
              :options="modalOptions"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        class="m-2"
        align-h="center"
        align-v="center"
      >
        <b-col cols="12">
          <ValidationObserver ref="transferForm">
            <b-form>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('amount')"
                rules="required"
              >
                <b-form-group
                  id="amount"
                  :label="$t('amount')"
                  label-for="amount"
                >
                  <b-form-input
                    id="amount"
                    v-model="transferForm.amount"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('description')"
                rules="required"
              >
                <b-form-group
                  id="description"
                  :label="$t('description')"
                  label-for="description"
                >
                  <b-form-input
                    id="description"
                    v-model="transferForm.description"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>

            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          varient="primary"
          class="mx-auto my-1"
          @click="transferMoneySubmit"
        >
          <span v-if="!isLoading">
            {{ $t('submit') }}</span>
          <b-spinner
            v-else
            small
            variant="light"
            type="grow"
          />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import IbClientTabs from '../components/ibClientTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis'
import { toFixed, numberWithCommas } from '@/utils'

const IB = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    IbClientTabs,
    BButton,
    BSpinner,
  },
  data() {
    return {
      toFixed,
      numberWithCommas,
      isLoading: false,
      show: true,
      required,
      accounts: [],
      transferModal: false,
      transferType: [1],
      transferForm: {
        amount: '',
        description: '',
        account: '',
        platform: '',
        type: '',
      },
      accountsfields: [
        { key: 'account', label: this.$t('account_id') },
        { key: 'type', label: this.$t('type') },
        { key: 'balance', label: this.$t('balance') },
        { key: 'equity', label: this.$t('equity') },
        { key: 'margin', label: this.$t('margin') },
        { key: 'group', label: this.$t('group') },
        { key: 'leverage', label: this.$t('leverage') },
        { key: 'actions', label: this.$t('actions') },
      ],
    }
  },
  computed: {
    modalOptions() {
      return [{ text: this.$t('deposit'), value: 'deposit' },
        { text: this.$t('rebate'), value: 'rebate' }]
    },
  },
  watch: {
    transferType(newVal, oldVal) {
      if (newVal.length >= 2) {
        return newVal.shift()
      }
      if (newVal.length === 0) {
        return newVal.push(oldVal[0])
      }
      return newVal
    },

  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.show = true
      IB.clientAccounts(this.$route.params.userID).then(res => {
        this.accounts = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    moneyTransferHandle(item) {
      this.transferModal = true
      this.transferForm.account = item.account
      this.transferForm.platform = item.type
    },
    async transferMoneySubmit() {
      this.isLoading = true
      const success = await this.$refs.transferForm.validate()
      if (success && this.transferType.length !== 0) {
        const data = {
          comment: this.transferForm.description, amount: this.transferForm.amount, account: this.transferForm.account, platform: this.transferForm.platform, type: this.transferForm.type,
        }
        await IB.moneyTransfer(this.$route.params.userID, data).then(res => {
          this.$swal({
            title: res.data.message,
            icon: 'success',
            timer: 2500,
            showConfirmButton: false,
          })
          this.transferModal = false
        }).catch((err => {
          this.transferModal = true
          this.transferForm.amount = ''
          this.transferForm.description = ''
          this.isLoading = false
          console.log(err)
        })).finally(() => {
          this.transferForm.amount = ''
          this.transferForm.description = ''
          this.isLoading = false
        })
      }
    },
  },

}
</script>
<style scoped lang="scss">
.type-style::v-deep .active{
  background-color: $primary !important;
}
</style>
